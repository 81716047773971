<div class="grid-layout">
  <div class="auth-promo">
    <div class="promo-content">
      <p>Create your free profile to personalize your experience. Find the best EV stations, engage with the community, and much more!</p>
      <img src="assets/icons/signup.svg" alt="signup image"/>

      <app-download-links></app-download-links>
    </div>
  </div>

  <div class="login-options">
    <form [formGroup]="loginForm" class="auth-form" (ngSubmit)="onSubmit($event)">
      <h3 class="form-title">Log In</h3>
      <div class="title-underline"></div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="loginForm.controls['email'].hasError('email')">Please enter a valid email</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-error *ngIf="loginForm.controls['password'].invalid">Passsword is required</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" class="create-account-button" [disabled]="isLoading()">
        <mat-spinner *ngIf="isLoading()" diameter="20"></mat-spinner>
        <span *ngIf="!isLoading()">Log In</span>
      </button>
    </form>
    <app-social-login></app-social-login>

    <div style="margin-top: 16px">
      <button mat-button class="toggle-button" (click)="openSignUpForm()">
        New user? Register
      </button>
    </div>
  </div>
</div>
