<div class="app-account-layout dash-container">
  <mat-nav-list class="account-nav">
    <a mat-list-item routerLink="/account" routerLinkActive="activated" [routerLinkActiveOptions]="{ exact: true }">Personal information</a>
    <a mat-list-item routerLink="/account/vehicles" routerLinkActive="activated">My vehicles</a>
    <a mat-list-item routerLink="/account/change-password" routerLinkActive="activated">Change password</a>
    <a
      mat-list-item
      routerLink="/account/wallet"
      routerLinkActive="activated"
      *ngIf="devCycleClient.getBooleanValue('wallet-settings-enable', false)"
    >
      Wallet
    </a>
  </mat-nav-list>
  <div class="account-content">
    <router-outlet></router-outlet>
  </div>
</div>
