<div class="app-connectors-tab">
  <div class="filters">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Search"
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchChargers()"
      />
    </mat-form-field>

    <button
      mat-flat-button
      class="filter-button"
      (click)="openFiltersBottomSheet()"
    >
      <mat-icon>filter_list</mat-icon> Filters
    </button>
  </div>

  <div class="no-results" *ngIf="chargers().length === 0" style="text-align: center">No results</div>

  <div class="chargers">
    <mat-card class="charger" *ngFor="let charger of chargers(); let i = index">
      <mat-card-header>
        <h3 class="name">{{ charger.name }}</h3>
        <button mat-icon-button (click)="toggleExpand(i)">
          <mat-icon>{{ !expandedStates.get(i) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </mat-card-header>

      <mat-card-content [style.display]="expandedStates.get(i) ? 'none' : 'block'">
        <div class="connectors">
          @for (connector of charger.connectors; track connector) {
          <app-connector-item [charger]="charger" [connector]="connector"></app-connector-item>
          }
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
