<div class="download-links">
  <a
      href="https://play.google.com/store/apps/details?id=com.gitcharger.app"
      target="_blank"
      >
      <img src="assets/playstore.svg" />
  </a>
    <a
        href="https://apps.apple.com/us/app/gitcharger/id6450578675"
        target="_blank"
        >
        <img src="assets/appstore.svg" />
    </a>
</div>
