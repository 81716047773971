<div id="map-base" class="map-container">
  <div
    id="gcMap"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (click)="onMapClick()"
    (leafletMapMove)="isMyLocationVisible()"
  ></div>

  <div class="connector-charger-status-container" *ngIf="devCycleClient.getBooleanValue('charger-status-enable', false)">
    <app-connector-charging-status></app-connector-charging-status>
  </div>

  <div class="map-controls">
    <button mat-icon-button (click)="jumpToMyLocation()" #jumpToMyLocationButton [disabled]="!isLocationAvailable()">
      <mat-icon>my_location</mat-icon>
    </button>
  </div>

  <!-- Position the search control at the top left -->
  <div class="topbar-search-bar">
    <button
      mat-icon-button
      onclick="document.querySelector('mat-sidenav').classList.toggle('opened')"
      class="show-mobile toggle-sidebar"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <div class="search-control-overlay">
      <app-search-control [map]="map"></app-search-control>
    </div>
  </div>

  <div class="hide-mobile topbar-user-dropdown">
    <app-user-dropdown></app-user-dropdown>
  </div>

  <router-outlet></router-outlet>
</div>
