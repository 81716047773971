import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteVehicleDialogComponent } from '@app/components/account/vehicles/delete-vehicle-dialog/delete-vehicle-dialog.component';
import { AddWalletDialogComponent } from '@app/components/wallet/add-wallet-dialog/add-wallet-dialog.component';
import { WalletTableComponent } from '@app/components/wallet/wallet-table/wallet-table.component';
import { appSchemeUrl } from '@app/pages/redirect-wallet/redirect-wallet.component';
import { AuthService } from '@app/services/auth.service';
import { Wallet } from '@app/services/user.interface';
import { UserService } from '@app/services/user.service';
import { firstValueFrom } from 'rxjs';
import { OpenFeature } from '@openfeature/web-sdk';

export const CARD_NAMES = {
  visa: "Visa",
  mastercard: "MasterCard",
  amex: "American Express",
  discover: "Discover",
  jcb: "JCB",
  diners_club: "Diners Club",
  unionpay: "UnionPay"
};

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [WalletTableComponent, MatIconModule, MatButtonModule, CommonModule, MatProgressSpinnerModule],
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss'
})
export class WalletComponent implements OnInit {
  wallets: Wallet[] = [];
  cardNames = CARD_NAMES;
  isLoading = signal(false);
  devCycleClient = OpenFeature.getClient();

  constructor(private dialog: MatDialog, private userService: UserService, private authService: AuthService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    if (!this.devCycleClient.getBooleanValue('wallet-settings-enable', false)) {
      this.router.navigate(['/account']);
      return;
    }

    await this.getWallets();
  }

  async getWallets() {
    const wallets = await firstValueFrom(this.userService.getWallets(this.authService.userSignal().uid));
    this.wallets = wallets;
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddWalletDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getWallets();

      this.redirectBackToApp();
    });
  }

  redirectBackToApp() {
    if ((this.route.snapshot.queryParams as any)?.from === 'app-redirect') {
      this.snackBar.open('Redirecting back to the app...', undefined, { duration: 3000 });

      setTimeout(() => {
        window.location.replace(appSchemeUrl); // Redirect back to the app
      }, 1500);
    }
  }

  async setAsDefault(id: string) {
    this.isLoading.set(true);
    await firstValueFrom(this.userService.selectDefaultCard(this.authService.userSignal().uid, id));

    await this.getWallets();
    this.isLoading.set(false);
    this.snackBar.open('Default card updated successfully', undefined, { duration: 3000 });

    this.redirectBackToApp();
  }

  async deleteWallet(id: string) {
    const dialogRef = this.dialog.open(DeleteVehicleDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.isLoading.set(true);
        await firstValueFrom(this.userService.deleteCard(this.authService.userSignal().uid, id));

        await this.getWallets();
        this.isLoading.set(false);
        this.snackBar.open('Card deleted successfully', undefined, { duration: 3000 });

        this.redirectBackToApp();
      }
    });
  }
}
