import { Component } from '@angular/core';

@Component({
  selector: 'app-download-links',
  standalone: true,
  imports: [],
  templateUrl: './download-links.component.html',
  styleUrl: './download-links.component.scss'
})
export class DownloadLinksComponent {

}
