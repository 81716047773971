"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const web_sdk_1 = require("@openfeature/web-sdk");
// Need to disable this to keep the working jest mock
// eslint-disable-next-line @nx/enforce-module-boundaries
const js_client_sdk_1 = require("@devcycle/js-client-sdk");
const DVCKnownPropertyKeyTypes = {
  email: 'string',
  name: 'string',
  language: 'string',
  country: 'string',
  appVersion: 'string',
  appBuild: 'number',
  customData: 'object',
  privateCustomData: 'object'
};
class DevCycleProvider {
  get devcycleClient() {
    return this._devcycleClient;
  }
  constructor(sdkKey, options = {}) {
    this.metadata = {
      name: 'devcycle-web-provider'
    };
    this.runsOn = 'client';
    this.events = new web_sdk_1.OpenFeatureEventEmitter();
    this._devcycleClient = null;
    this.sdkKey = sdkKey;
    if (!options.sdkPlatform) {
      options.sdkPlatform = 'js-of';
    } else if (!options.sdkPlatform.toLowerCase().endsWith('-of')) {
      options.sdkPlatform = `${options.sdkPlatform}-of`;
    }
    this.options = options;
  }
  async initialize(context) {
    this._devcycleClient = await (0, js_client_sdk_1.initializeDevCycle)(this.sdkKey, context ? this.dvcUserFromContext(context) : {}, this.options).onClientInitialized();
    this._devcycleClient.eventEmitter.subscribe('configUpdated', allVariables => {
      this.events.emit(web_sdk_1.ProviderEvents.ConfigurationChanged, allVariables);
    });
    this._devcycleClient.eventEmitter.subscribe('error', error => {
      this.events.emit(web_sdk_1.ProviderEvents.Error, error);
    });
    if (!context) {
      this._devcycleClient.logger.warn('DevCycle initialized without context being set. ' + 'It is highly recommended to set a context `OpenFeature.setContext()` ' + 'before setting an OpenFeature Provider `OpenFeature.setProvider()` ' + 'to avoid multiple API fetch calls.');
    }
  }
  get status() {
    var _a;
    return ((_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.isInitialized) ? web_sdk_1.ProviderStatus.READY : web_sdk_1.ProviderStatus.NOT_READY;
  }
  async onClose() {
    var _a;
    await ((_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.close());
  }
  async onContextChange(oldContext, newContext) {
    var _a;
    await ((_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.identifyUser(this.dvcUserFromContext(newContext)));
  }
  track(trackingEventName, context, trackingEventDetails) {
    var _a;
    (_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.track({
      type: trackingEventName,
      value: trackingEventDetails === null || trackingEventDetails === void 0 ? void 0 : trackingEventDetails.value,
      metaData: trackingEventDetails && {
        ...trackingEventDetails,
        value: undefined
      }
    });
  }
  /**
   * Generic function to retrieve a DVC variable and convert it to a ResolutionDetails.
   * @param flagKey
   * @param dvcDefaultValue
   * @param ofDefaultValue
   * @private
   */
  getDVCVariable(flagKey, dvcDefaultValue, ofDefaultValue) {
    var _a;
    const dvcVariable = (_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.variable(flagKey, dvcDefaultValue);
    return this.resultFromDVCVariable(dvcVariable, ofDefaultValue);
  }
  /**
   * Resolve a boolean OpenFeature flag and its evaluation details.
   * @param flagKey
   * @param defaultValue
   */
  resolveBooleanEvaluation(flagKey, defaultValue) {
    return this.getDVCVariable(flagKey, defaultValue, defaultValue);
  }
  /**
   * Resolve a string OpenFeature flag and its evaluation details.
   * @param flagKey
   * @param defaultValue
   */
  resolveStringEvaluation(flagKey, defaultValue) {
    return this.getDVCVariable(flagKey, defaultValue, defaultValue);
  }
  /**
   * Resolve a number OpenFeature flag and its evaluation details.
   * @param flagKey
   * @param defaultValue
   */
  resolveNumberEvaluation(flagKey, defaultValue) {
    return this.getDVCVariable(flagKey, defaultValue, defaultValue);
  }
  /**
   * Resolve an object OpenFeature flag and its evaluation details.
   * @param flagKey
   * @param defaultValue
   */
  resolveObjectEvaluation(flagKey, defaultValue) {
    return this.getDVCVariable(flagKey, this.defaultValueFromJsonValue(defaultValue), defaultValue);
  }
  /**
   * Convert a OpenFeature JsonValue default value into DVCJSON default value for evaluation.
   * @param jsonValue
   * @private
   */
  defaultValueFromJsonValue(jsonValue) {
    if (typeof jsonValue !== 'object' || Array.isArray(jsonValue)) {
      throw new web_sdk_1.ParseError('DevCycle only supports object values for JSON flags');
    }
    if (!jsonValue) {
      throw new web_sdk_1.ParseError('DevCycle does not support null default values for JSON flags');
    }
    // Hard casting here because our DVCJSON typing enforces a flat object when we actually support
    // a JSON Object of any depth. Will be fixed soon.
    return jsonValue;
  }
  /**
   * Convert a DVCVariable result into a OpenFeature ResolutionDetails.
   * TODO: add support for variant / reason / and more error codes from DVC.
   * @param variable
   * @param ofDefaultValue
   * @private
   */
  resultFromDVCVariable(variable, ofDefaultValue) {
    return variable ? {
      value: variable.value,
      reason: variable.isDefaulted ? web_sdk_1.StandardResolutionReasons.DEFAULT : web_sdk_1.StandardResolutionReasons.TARGETING_MATCH
    } : {
      value: ofDefaultValue,
      reason: web_sdk_1.StandardResolutionReasons.DEFAULT
    };
  }
  /**
   * Convert an OpenFeature EvaluationContext into a DevCycleUser.
   * @param context
   * @private
   */
  dvcUserFromContext(context) {
    var _a, _b, _c, _d;
    const user_id = (_a = context.targetingKey) !== null && _a !== void 0 ? _a : context.user_id;
    if (!user_id) {
      throw new web_sdk_1.TargetingKeyMissingError('Missing targetingKey or user_id in context');
    }
    if (typeof user_id !== 'string') {
      throw new web_sdk_1.InvalidContextError('targetingKey or user_id must be a string');
    }
    const dvcUserData = {};
    let customData = {};
    let privateCustomData = {};
    for (const [key, value] of Object.entries(context)) {
      if (key === 'targetingKey' || key === 'user_id') continue;
      const knownValueType = DVCKnownPropertyKeyTypes[key];
      if (knownValueType) {
        if (typeof value !== knownValueType) {
          (_b = this._devcycleClient) === null || _b === void 0 ? void 0 : _b.logger.warn(`Expected DevCycleUser property "${key}" to be "${knownValueType}" but got ` + `"${typeof value}" in EvaluationContext. Ignoring value.`);
          continue;
        }
        switch (knownValueType) {
          case 'string':
            dvcUserData[key] = value;
            break;
          case 'number':
            dvcUserData[key] = value;
            break;
          case 'object':
            if (key === 'privateCustomData') {
              privateCustomData = this.convertToDVCCustomDataJSON(value);
            } else if (key === 'customData') {
              customData = {
                ...customData,
                ...this.convertToDVCCustomDataJSON(value)
              };
            }
            break;
          default:
            break;
        }
      } else {
        switch (typeof value) {
          case 'string':
            customData[key] = value;
            break;
          case 'number':
            customData[key] = value;
            break;
          case 'boolean':
            customData[key] = value;
            break;
          case 'object':
            if (value === null) {
              customData[key] = null;
              break;
            }
            (_c = this._devcycleClient) === null || _c === void 0 ? void 0 : _c.logger.warn(`EvaluationContext property "${key}" is an ${Array.isArray(value) ? 'Array' : 'Object'}. ` + 'DevCycleUser only supports flat customData properties of type ' + 'string / number / boolean / null');
            break;
          default:
            (_d = this._devcycleClient) === null || _d === void 0 ? void 0 : _d.logger.warn(`Unknown EvaluationContext property "${key}" type. ` + 'DevCycleUser only supports flat customData properties of type ' + 'string / number / boolean / null');
            break;
        }
      }
    }
    return {
      user_id,
      customData: Object.keys(customData).length ? customData : undefined,
      privateCustomData: Object.keys(privateCustomData).length ? privateCustomData : undefined,
      ...dvcUserData
    };
  }
  /**
   * Convert customData from an OpenFeature EvaluationContextObject into a DevCycleUser customData.
   * @param evaluationData
   * @private
   */
  convertToDVCCustomDataJSON(evaluationData) {
    var _a;
    const customData = {};
    for (const [key, value] of Object.entries(evaluationData)) {
      switch (typeof value) {
        case 'string':
          customData[key] = value;
          break;
        case 'number':
          customData[key] = value;
          break;
        case 'boolean':
          customData[key] = value;
          break;
        case 'undefined':
          customData[key] = null;
          break;
        default:
          (_a = this._devcycleClient) === null || _a === void 0 ? void 0 : _a.logger.warn(`EvaluationContext property "customData" contains "${key}" property of type ${typeof value}.` + 'DevCycleUser only supports flat customData properties of type ' + 'string / number / boolean / null');
          break;
      }
    }
    return customData;
  }
}
exports.default = DevCycleProvider;
