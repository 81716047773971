<div class="grid-layout">
  <div class="auth-promo">
    <div class="promo-content">
      <p>Create your free profile to personalize your experience. Find the best EV stations, engage with the community, and much more!</p>
      <img src="assets/icons/signup.svg" alt="signup image"/>

      <app-download-links></app-download-links>
    </div>
  </div>

  <div class="login-options">
    <form [formGroup]="verifyForm" class="auth-form" (ngSubmit)="onVerify($event)">
      <h3 class="form-title">Verify your account</h3>
      <div class="title-underline"></div>
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput formControlName="code" />
        <mat-error *ngIf="verifyForm.controls['code'].hasError('required')">OTP is required</mat-error>
        <mat-error *ngIf="verifyForm.controls['code'].hasError('minlength') || verifyForm.controls['code'].hasError('maxlength')">OTP must be 6 digits</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" class="create-account-button" [disabled]="isLoading()">
        <mat-spinner *ngIf="isLoading()" diameter="20"></mat-spinner>
        <span *ngIf="!isLoading()">Verify</span>
      </button>
      <button mat-button (click)="resendVerificationCode()" type="button" [disabled]="isResendButtonDisabled()">Resend code {{ isResendButtonDisabled() ? '(' + formattedTime() + ')' : '' }}</button>
    </form>
    <app-social-login></app-social-login>

    <div style="margin-top: 16px">
      <button mat-button class="toggle-button" (click)="openSignInForm()">
        Already have an account? Log In
      </button>
    </div>
  </div>
</div>
