import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './services/auth.service';
import { Analytics, isSupported, logEvent } from '@angular/fire/analytics';
import { OpenFeature } from '@openfeature/web-sdk';
import DevCycleProvider from '@devcycle/openfeature-web-provider';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MatSidenavModule,
    MatToolbarModule,
    SidebarComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(iconRegistry: MatIconRegistry, private authService: AuthService, private analytics: Analytics) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    isSupported().then((supported) => {
      if (supported) {
        console.log('🔥 Firebase Analytics is enabled');
        logEvent(this.analytics, 'test_event', { message: 'Firebase Analytics is working!' });
      } else {
        console.warn('⚠️ Firebase Analytics is not supported in this environment');
      }
    });
  }
  title = 'gitcharger-web';

  async ngOnInit() {
    const user = { targetingKey: 'musab_targeting_key' };
    const devcycleProvider = new DevCycleProvider(environment.devcycleClientSdkKey);
    await OpenFeature.setContext(user);
    await OpenFeature.setProviderAndWait(devcycleProvider);

    this.authService.getUserObject().subscribe((user) => {
      this.authService.userSignal.set(user ?? null);
    });
  }
}
